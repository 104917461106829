import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
	user: IUser | null;
	modalAuthOpen: boolean;
	modalWalletOpen: boolean;
	modalWalletState: 'add' | 'switch' | null;
	switchWalletAddress: string | null;
}

import { IUser } from 'src/hooks/reducers/useAuthReducer';

export const authSlice = createSlice({
	name: 'authSlice',
	initialState: {
		user: null,
		modalAuthOpen: false,
		modalWalletOpen: false,
		modalWalletState: null,
		switchWalletAddress: null,
	} as IAuthState,
	reducers: {
		setAuthUserData(state: IAuthState, { payload }: PayloadAction<{ user: IAuthState['user'] }>) {
			Object.assign(state, payload);
		},
		setModalAuthOpen(state: IAuthState, { payload }: PayloadAction<IAuthState['modalAuthOpen']>) {
			state.modalAuthOpen = payload;
		},
		setModalWalletOpen(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['modalWalletOpen']>,
		) {
			state.modalWalletOpen = payload;
		},
		setModalWalletState(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['modalWalletState']>,
		) {
			state.modalWalletState = payload;
		},
		setSwitchWalletAddress(
			state: IAuthState,
			{ payload }: PayloadAction<IAuthState['switchWalletAddress']>,
		) {
			state.switchWalletAddress = payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setAuthUserData,
	setModalAuthOpen,
	setModalWalletOpen,
	setModalWalletState,
	setSwitchWalletAddress,
} = authSlice.actions;

export default authSlice;

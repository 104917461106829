/* eslint-disable no-multi-assign */
import { useState } from 'react';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import _ from 'lodash';

import { useWeb3Context } from '../context/web3/Web3Context';
import useAuthReducer from './reducers/useAuthReducer';

const TIMEOUTLIMIT = 30000;

interface IAxiosConfig {
	endpoint: string;
	method?: string;
	data?: any;
	params?: any;
	headers?: any;
}

export const useAPICaller = () => {
	const { user, clearUser } = useAuthReducer();
	const [isLoading, setisLoading] = useState<boolean>(false);
	const [data, setData] = useState<any>(null);
	const { disconnect } = useWeb3Context();

	const axiosInstance = () => {
		const axiosConfig = {
			headers: {
				'Content-Type': 'application/json',
			},
			baseURL:
				process.env.NEXT_PUBLIC_FLAVOR === 'MAINNET'
					? process.env.NEXT_PUBLIC_API_URL
					: process.env.NEXT_PUBLIC_API_URL_DEV,
			timeout: TIMEOUTLIMIT,
			validateStatus(status: number) {
				return status < StatusCodes.INTERNAL_SERVER_ERROR && status !== StatusCodes.FORBIDDEN;
			},
		};

		const instance = axios.create(axiosConfig);

		instance.interceptors.request.use((cfg: any) => {
			if (user?.api_token) {
				// eslint-disable-next-line no-param-reassign
				cfg.headers.Authorization = `Bearer ${user?.api_token}`;
			}
			return cfg;
		});
		return instance;
	};

	const service = (config: IAxiosConfig) => {
		const axiosService = axiosInstance();
		const apiConfig = {
			url: config.endpoint,
			method: config.method ? config.method : 'GET',
			data: config.data ? config.data : undefined,
			params: config.params ? config.params : undefined,
			headers: config.headers ? config.headers : undefined,
		};

		return axiosService(apiConfig);
	};

	const fetchAPI = async (config: IAxiosConfig) => {
		setisLoading(true);
		let response = null;
		try {
			response = await service(config);
			if (response.status === StatusCodes.OK) {
				let method = 'GET';
				if (config.method) {
					method = config.method;
				}
				if (method === 'GET') {
					// the original should be like this
					if (!_.isEmpty(response.data.data)) {
						setData(response.data.data);
					} else {
						setData(null);
					}
				}
			}
			setisLoading(false);
			return response;
		} catch (e: any) {
			if (e?.response?.status === 403) {
				clearUser();
				disconnect();
			}
			// response = e.response;
			setisLoading(false);
			throw e;
		}
		// setisLoading(false);
		// return response;
	};

	return { fetchAPI, isLoading, data };
};

export default useAPICaller;
